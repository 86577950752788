import { Canvas } from "@react-three/fiber";
import React, { FC } from "react";
import Box from "./Box";

const App: FC = () => {
  return (
    <div
      className="flex bg-red-500"
      style={{ height: "100vh", width: "100vw" }}
    >
      <body className="flex-1 h-full w-full">
        <Canvas>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Box position={[-1.2, 0, 0]} />
          <Box position={[1.2, 0, 0]} />
        </Canvas>
      </body>
    </div>
  );
};

export default App;
