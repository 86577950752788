import { MeshProps, useFrame } from "@react-three/fiber";
import React, { useRef, useState } from "react";

const Box = (props: MeshProps) => {
  // This reference will give us direct access to the THREE.Mesh object
  const mesh = useRef<any>();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame

  useFrame((state, delta) => {
    // if (mesh?.current?.rotation?.x) {
    mesh.current.rotation.x += 0.01;
    mesh.current.rotation.y += Math.sin(mesh.current.position.x * 0.01);
    // mesh.current.size.x += Math.sin(mesh.current.position.x)
  });

  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : `orange`} />
    </mesh>
  );
};

export default Box;
